<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="about" pa-5>
      <v-flex xs6>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >About Us</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 text-right py-5>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark color="green" v-bind="attrs" v-on="on"> UPDATE </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Updates Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span>Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="about.description"></vue-editor>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="update()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
      <!-- <v-flex xs12 sm6 md4>
                <v-img :src="mediaURL + about.banner" contain height="200px"></v-img>
            </v-flex> -->
      <v-flex xs12 sm12 md12 pa-2 align-self-center v-if="about.description">
        <span
          style="
            font-family: poppinsregular;
            font-size: 14px;
            text-align: justify;
          "
          v-html="about.description"
        ></span>
      </v-flex>
      <v-layout wrap pa-5>
        <v-flex xs12>
          <span style="font-family: poppinsbold; font-size: 18px">
            Officials
            <br />
            <br />
          </span>
        </v-flex>
        <!-- <v-flex xs12 sm6 md4>
                    <v-img :src="mediaURL + about.expertise.image"></v-img>
                </v-flex> -->
        <v-flex
          xs12
          sm6
          md3
          pa-2
          align-self-center
          v-for="(item, i) in officials"
          :key="i"
        >
          <v-card outlined height="300px">
            <v-layout wrap justify-center py-5 px-5>
              <v-flex xs12 sm6 md4>
                    <v-img :src="mediaUURL + item.image"></v-img>
              </v-flex>
              <v-flex xs12>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{ item.name }}
                  <br />
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                    >{{ item.designation }}</span
                  >
                </span>
              </v-flex>
              <v-layout wrap pt-5>
                <v-flex xs12 sm12 pa-2>
                  <v-btn
                    small
                    block
                    class="mr-2"
                    outlined
                    color="green"
                    @click="editSlider(item)"
                  >
                    Edit
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="editdialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Blog</span>
          </v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  v-model="editingitem.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  v-model="editingitem.designation"
                  label="Designation"
                  required
                ></v-text-field>
              </v-col>
              <v-flex
                xs10
                md12
                lg12
                px-5
                align-self-center
                text-center
                pb-2
                style="
                  border-style: dotted;
                  border-color: black;
                  background-color: white;
                "
              >
                <v-layout wrap justify-center pa-2>
                  <v-flex xs12>
                    <v-img
                      v-if="editingitem.image && !imagePreview"
                      :src="mediaUURL + editingitem.image"
                      height="200px"
                      style="align-center"
                      contain
                    />
                    <img
                      v-if="imagePreview"
                      :src="imagePreview"
                      v-show="showPreview"
                      class="align-center"
                      height="200px"
                      contain
                    />
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <v-icon
                      small
                      @click="$refs.showImage.click()"
                      color="black"
                      style="font-family: poppinssemibold; font-size: 17px"
                      >mdi-plus</v-icon
                    >
                    <span style="font-family: poppinssemibold; font-size: 15px"
                      >Upload Image</span
                    >
                    <input
                      v-show="false"
                      id="file"
                      ref="showImage"
                      multiple
                      type="file"
                      @change="showImage"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editdialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
export default {
  components: {
    VueEditor,
    // ImageComp,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      aboutBannerImage: "",
      imagePreview: "",
      showPreview: false,
      file: "",
      about: {},
      itemediting: [],
      editdialog: false,
      editingitem: {},
      officials: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "aboutBannerImage") {
        this.aboutBannerImage = window_data.selectedFiles;
      }
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/view/website/content",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.websiteDetails.aboutUs;
          this.officials = response.data.officials;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      var data = {};
      data["description"] = this.about.description;
      axios({
        url: "/edit/aboutUs",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/officials/image/addOrEdit",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.editdialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["name"] = this.editingitem.name;
      user["designation"] = this.editingitem.designation;
      user["id"] = this.editingitem._id;
      axios({
        url: "/edit/official/details",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.file) {
              this.uploadImage(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>
  